<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Choose an item</v-toolbar-title>

            <v-spacer />
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-text-field
                        label="Search"
                        v-model="query"
                        placeholder="Seach for an item"
                        @input="queryChanged"
                    />
                </v-row>
            </v-container>
        </v-card-text>
        <div v-if="loading" class="px-3">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>
        <div v-else-if="total === 0">
            <h4 class="grey-text font-weight-light mt-3 mb-5 px-3">
                No results found.
            </h4>
        </div>
        <div v-else-if="total > 0" class="mt-3 px-3">
            <v-card
                class="mx-auto mb-3"
                outlined
                v-for="result in results"
                :key="result.id"
                @click.stop="itemPicked(result)"
            >
                <v-card-title>
                    <span class="font-weight-bold grey--text text--darken-3">{{
                        result.itemCode
                    }}</span>
                </v-card-title>
                <v-card-subtitle class="font-weight-bold pb-1 card-text-small">
                    {{ result.itemName }}
                </v-card-subtitle>
            </v-card>
        </div>
        <div class="pb-3 px-3">
            <v-btn
                outlined
                rounded
                block
                color="primary"
                v-if="!loading && hasMore"
                :loading="loadingMore"
                @click.stop="loadMore"
            >
                More
            </v-btn>
        </div>
    </v-card>
</template>
<script>
import payments from "./paymentsAgent";
import debounce from "@/util/debounce";

const PAGE_SIZE = 20;

export default {
    props: {
        sharedoId: {
            type: String,
            required: true,
        },
        paymentRequestId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            query: "",
            page: 0,
            results: [],
            total: -1,
            loadingMore: false,
            loading: false,
        };
    },
    computed: {
        hasMore: function () {
            if (this.total <= 0) {
                return false;
            }

            const totalPages = Math.ceil(this.total / PAGE_SIZE);
            return totalPages > this.page + 1;
        },
    },
    methods: {
        search: async function (reload = true) {
            if (this.query) {
                try {
                    this.loading = true;

                    const workingSet = reload ? [] : [...this.results];

                    const response = await payments.searchGoodsAndServices(
                        this.sharedoId,
                        this.paymentRequestId,
                        this.query,
                        this.page,
                        PAGE_SIZE
                    );

                    workingSet.push(
                        ...response.results.map((result) => ({
                            ...result.data,
                        }))
                    );

                    this.total = response.resultCount;
                    this.results = workingSet;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            }
        },
        loadMore: async function () {
            this.loadingMore = true;
            this.page++;
            await this.search(false);
            this.loadingMore = false;
        },
        itemPicked: function (item) {
            this.close({ ...item });
        },
        close: function (result) {
            this.$emit("close", result);
        },
    },
    created: function () {
        const vm = this;

        vm.queryChanged = debounce(async function () {
            vm.page = 0;
            await vm.search();
        }, 500);
    },
};
</script>